<template>
  <ShowTimeTable 
    v-if="isTimeTable"
    :enclst="enclst"
  />
  <ShowList 
    v-else
    :enclst="enclst"
  />
</template>

<script>
import ShowList from "@/components/ShowList.vue"
import ShowTimeTable from "@/components/ShowTimeTable.vue"
export default {
  components:{ShowList,ShowTimeTable},
  props: {
    enclst: {
      type: Object,
      default: () => {}
    },
    isTimeTable: {
      type: Boolean,
      default: false
    }
  }
}
</script>