<template>
  <v-list>
      <v-row class="ma-1">
        <AllFromNowSelector
          @selected="allFromNowSelected"
        />
      </v-row>
      <TimeTable 
        v-for="(item,i) in sortedItems"
        :key="i"
        :item="item"
        :all="all"
      />
  </v-list>
</template>

<script>
import TimeTable from "@/components/TimeTable.vue"
import AllFromNowSelector from "@/components/AllFromNowSelector.vue"
//import { EncLst } from "enclst-core-js"
export default {
  components:{TimeTable,AllFromNowSelector},
  props: {
    enclst: {
      type: Object,
      default: () => {}
    },
  },
  data: function(){
    return {
    //
      all: false,
      sortedItems: this.enclst.items.sort(this.comparefn),
    }},
  methods: {
    allFromNowSelected(i){
      this.all = i
    },
    // compare function for sorting an Items
    comparefn(a, b){
      console.log("a", a)
      console.log("b", b)
      let aFirst = this.timeadjust(a.value.first())
      let bFirst = this.timeadjust(b.value.first())
      console.log("aFirst", aFirst)
      console.log("bFirst", bFirst)
      if (aFirst.toString(10).length <= 2){
        const aPositional = a.value.positionalValues
        const bPositional = b.value.positionalValues
        aFirst = this.timeadjust(aPositional[0] + aPositional[1]) 
        bFirst = this.timeadjust(bPositional[0] + bPositional[1])
      }
      console.log("aFirst", aFirst)
      console.log("bFirst", bFirst)
      if (aFirst == bFirst){
        return 0
      } else if (aFirst > bFirst){
        return 1
      } else {
        return -1
      }
    },
    timeadjust(a){
      let resultStr = a
      if (resultStr.length == 3){
        resultStr = "0".concat(resultStr)
      }
      if (resultStr.substring(0,2) == "00"){
        resultStr = "24".concat(resultStr.substring(2,4))
      }
      return resultStr
    }
  },
}
</script>

