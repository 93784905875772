<template>
  <v-btn 
    class="ml-auto" 
    text
    @click="click"
  >
    {{title}}
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    //
    all: false,
  }),
  methods:{
    click(){
      this.all = !this.all
      this.$emit("selected", this.all)
    },
  },
  computed: {
    title(){
      if (this.all){
        return "From Now Only"
      } else {
        return "Show All"
      } 
    }
  }
}
</script>